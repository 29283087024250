<template>
  <main-layout>
    <div :class="$style.container">
      <div :class="$style.bottomCircle">
        <div :class="$style.circle1" />
      </div>
      <div :class="$style.centerCircles">
        <div :class="$style.circle2" />
        <div :class="$style.circle3" />
      </div>
      <div :class="$style.topFill"></div>
      <div :class="$style.logoMottoInfo">
        <div :class="$style.logo">
          <asset name="main-logo" :alt="$t('app_title')" />
          <div :class="$style.motto">{{ $t('home_subtitle') }}</div>
        </div>
        <div :class="$style.info">
          <p>{{ $t('home_info_part1') }}</p>
          <p>{{ $t('home_info_part2') }}</p>
          <p>{{ $t('home_info_part3') }}</p>
          <i18n-t keypath="home_info_part4" tag="p">
            <a href="mailto:feedback@mobyyou.com">feedback@mobyyou.com</a>
          </i18n-t>
          <p :class="$style.termsLinks">
            <a
              href="/documents/mobyyou_algemene_voorwaarden.20220405.pdf"
              download
            >
              {{ $t('terms_conditions') }}
            </a>
            <a
              href="/documents/mobyyou_privacy_verklaring.20220421.pdf"
              download
            >
              {{ $t('privacy_policy') }}
            </a>
          </p>
          <p :class="$style.kvkInfo">
            {{ $t('home_kvk_info') }}
          </p>
        </div>
      </div>
      <div :class="$style.bottomFill"></div>
      <png-asset name="main-footer-phone-teaser" :class="$style.bottomImage" />
    </div>
  </main-layout>
</template>

<style lang="scss" module>
.container {
  display: flex;
  transform: none;
  -webkit-transform: translate3d(0, 0, 0);
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available; // Fix for Safari height
  }
  flex-direction: column;
  @include tablet {
    justify-content: flex-end;
    align-items: center;
    min-height: 100vh;
  }
  @include mobile {
    min-height: 100vh;
    padding-top: 32px;
  }
}

.logoMottoInfo {
  z-index: 3;
  @include tablet {
    padding-top: 32px;
    max-width: 400px;
  }
  @include mobile {
    padding-top: 16px;
  }
}

.logo {
  margin-bottom: 36px;
  position: relative;
  display: inline-block;
}

.motto {
  color: get-color(lightHighlight);
  @include apply-font(marketingPayoff);
  position: absolute;
  right: 0;
  bottom: -16px;
}

.info {
  color: get-color(lightHighlight);
  @include apply-font(body);
  text-align: left;
  @include tablet {
    padding: 72px 24px 0;
  }
  @include mobile {
    padding: 16px 24px 0;
  }
}

.info p {
  margin-bottom: 16px;
}

.topFill {
  flex-grow: 1;
  @include mobile {
    display: none;
  }
}

.bottomFill {
  flex-grow: 1;
}

.bottomImage {
  position: relative;
  display: block;
  z-index: 2;
}

.bottomImage img {
  max-width: none;
}

.circle1,
.circle2,
.circle3 {
  background-color: get-color(primaryNeutral);
  border-radius: 50%;
}

.bottomCircle {
  position: fixed;
  z-index: 1;
  width: 375px;
  height: 375px;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.circle1 {
  position: absolute;
  width: 375px;
  height: 375px;
  left: -120px;
  bottom: -160px;
}

.centerCircles {
  position: fixed;
  z-index: 1;
  @include tablet {
    top: 50%;
    transform: translate(-70px, -410px);
    width: 600px;
    height: 320px;
  }
  @include mobile {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0;
  }
}

.circle2 {
  position: fixed;
  width: 320px;
  height: 320px;
  @include tablet {
    left: 0;
    top: 0;
  }
  @include mobile {
    top: -160px;
    left: -160px;
  }
}

.circle3 {
  position: fixed;
  width: 94px;
  height: 94px;
  @include tablet {
    top: 210px;
    right: -120px;
  }
  @include mobile {
    top: 24px;
    right: -24px;
  }
}

.termsLinks {
  margin-top: 40px;
}

.termsLinks a,
.termsLinks a:hover,
.termsLinks a:visited {
  text-decoration: underline;
  color: get-color(primaryLightest);
  margin-right: 24px;
}

.kvkInfo {
  color: get-color(primaryLightest);
  margin-top: 40px;
}
</style>
